.hiw-bg-smile-anim {
    animation-timing-function: ease;
    position: absolute;
    width: 45px;
    height: 45px;
    visibility: visible;
}

.hiw-bg-smile-anim.normal {
    bottom: 56%;
    left: 10%;
    transform: rotate(390deg);
    -webkit-animation: smiley 2s;
    /* Safari 4.0 - 8.0 */
    animation: smiley 2s;
}
/* Safari 4.0 - 8.0 */
@-webkit-keyframes smiley {
    from {
        bottom: 0;
        left: 0;
        transform: rotate(0deg);
    }
    to {
        bottom: 56%;
        left: 10%;
        transform: rotate(360deg);
    }
}
/* Standard syntax */
@keyframes smiley {
    from {
        bottom: 0;
        left: 0;
        transform: rotate(0deg);
    }
    to {
        bottom: 56%;
        left: 10%;
        transform: rotate(390deg);
    }
}


.hiw-bg-smile-anim.blue {
    color: #039BE5 !important;
    bottom: 64%;
    right: 8%;
    transform: rotate(300deg);
    -webkit-animation: smileyBlue 2s;
    /* Safari 4.0 - 8.0 */
    animation: smileyBlue 2s;
}
.hiw-bg-smile-anim.blue path {
    fill: #039BE5 !important;
}
/* Safari 4.0 - 8.0 */
@-webkit-keyframes smileyBlue {
    from {
        bottom: 0;
        right: 0;
        transform: rotate(0deg);
    }
    to {
        bottom: 64%;
        right: 8%;
        transform: rotate(360deg);
    }
}
/* Standard syntax */

@keyframes smileyBlue {
    from {
        bottom: 0;
        right: 0;
        transform: rotate(0deg);
    }
    to {
        bottom: 64%;
        right: 8%;
        transform: rotate(300deg);
    }
}



.hiw-bg-smile-anim.green {
    color: var(--highlight);
    bottom: 48%;
    right: 24%;
    transform: rotate(370deg);
    -webkit-animation: smileyGreen 2s;
    /* Safari 4.0 - 8.0 */
    animation: smileyGreen 2s;
}
.hiw-bg-smile-anim.green path {
    fill: #1FD494 !important;
}
@-webkit-keyframes smileyGreen {
    from {
        bottom: 0;
        right: 30%;
        transform: rotate(0deg);
    }
    to {
        bottom: 48%;
        right: 24%;
        transform: rotate(370deg);
    }
}
/* Standard syntax */
@keyframes smileyGreen {
    from {
        bottom: 0;
        right: 30%;
        transform: rotate(0deg);
    }
    to {
        bottom: 48%;
        right: 24%;
        transform: rotate(370deg);
    }
}


.hiw-bg-smile-anim.pink {
    color: var(--danger);
    left: 45%;
    top: 24%;
    transform: rotate(320deg);
    -webkit-animation: smileyPink 2s;
    /* Safari 4.0 - 8.0 */
    animation: smileyPink 2s;
}
.hiw-bg-smile-anim.pink path {
    fill: #F06292 !important;
}
@-webkit-keyframes smileyPink {
    from {
        top: 0;
        left: 10%;
        transform: rotate(0deg);
    }
    to {
        left: 45%;
        top: 24%;
        transform: rotate(320deg);
    }
}
/* Standard syntax */
@keyframes smileyPink {
    from {
        top: 0;
        left: 10%;
        transform: rotate(0deg);
    }
    to {
        left: 45%;
        top: 24%;
        transform: rotate(320deg);
    }
}