/* -------------------------------------------
      Background color classes 
--------------------------------------------*/

.primary {
    background-color: var(--primary) !important;
    color: white !important;
}

button.primary:hover {
    background-color: var(--primary-hover) !important;
}

.secondary {
    background-color: var(--secondary) !important;
    color: var(--darktext) !important;
}

.light-secondary {
    background-color: var(--secondary-hover) !important;
}

button.secondary:hover {
    background-color: var(--secondary-hover) !important;
}

.danger {
    background-color: var(--danger) !important;
    color: white !important;
}

button.danger:hover {
    background-color: var(--danger-hover) !important;
}

.success {
    background-color: var(--success) !important;
    color: white !important;
}

button.success:hover {
    background-color: var(--success-hover) !important;
}

.disabled {
    background-color: var(--lighttext) !important;
    color: white !important;
}

.darktext{
    background-color: var(--darktext) !important;
    color: white !important;
}