@import './variables.css';
@import './text.css';
@import './background.css';
@import './typing.css';

@font-face {
  font-family: AktivGrotesk;
  src: url('/fonts/aktiv-grotesk-w01.woff') format("woff"),
  url('/fonts/aktiv-grotesk-w01.woff2') format("woff2");
  src: url('/fonts/aktiv-grotesk-w01.ttf');
}

* {
  font-family: AktivGrotesk;
}

*::selection {
  color: white;
  background-color: var(--primary);
}

body {
  margin: unset !important;
  background-color: #f4f6fa;
  width: 100vw !important;
  max-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden !important;
}

a, .cursor {
  cursor: pointer;
}

input {
  color: var(--darktext) !important;
}

.MuiButtonBase-root:not(li), Button {
  text-transform: lowercase !important;
  font-weight: 700 !important;
}

.default-width {
  max-width: var(--default-width);
}

/* Added primary colors to input borders and lines */
.MuiTextField-root .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--primary) !important;
}
.MuiTextField-root .Mui-focused {
  color: var(--primary) !important;
}
.MuiInput-underline:after {
  border-color: var(--primary) !important;
}

.swalLP{
  width: 70vw;
  min-height: 70vh;
  text-align: left !important;
}

.swalLP .swal-footer{
  position: absolute;
  bottom: 0;
  right: 0;
}