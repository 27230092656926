.typewriter {
    display: inline-block;
}

@media screen and (min-width: 1280px) {
    .typewriter h1 {
        overflow: hidden;
        /* Ensures the content is not revealed until the animation */
        /* The typwriter cursor */
        white-space: nowrap;
        /* Keeps the content on a single line */
        margin: 0 auto;
        /* Gives that scrolling effect as the typing happens */
        /* Adjust as needed */
        animation: typing 2s steps(40, end);
    }
}

@media screen and (max-width: 1279px) {
    .typewriter h1 {
        /* Gives that scrolling effect as the typing happens */
        /* Adjust as needed */
        animation: typing_mobile 3s;
    }
}

/* The typing effect */
@keyframes typing {
    from {
        width: 0
    }
    to {
        width: 100%
    }
}

/* The typing effect mobile */
@keyframes typing_mobile {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
