.alert-box.opened {
  -webkit-animation: alertup 0.6s;
  /* Safari 4.0 - 8.0 */
  animation: alertup 0.6s;
}

.alert-box.closed {
  /* opacity: 0; */
  -webkit-animation: alertdown 0.6s;
  /* Safari 4.0 - 8.0 */
  animation: alertdown 0.6s;
  box-shadow: none;
}

@-webkit-keyframes alertup {
  from {
    bottom: -100px;
    position: fixed;
  }
  to {
    bottom: 15px;
    position: fixed;
  }
}

/* Standard syntax */

@keyframes alertup {
  from {
    bottom: -100px;
    position: fixed;
  }
  to {
    bottom: 15px;
    position: fixed;
  }
}


@-webkit-keyframes alertdown {
  from {
    bottom: 15px;
    position: fixed;
    opacity: 1;
  }
  to {
    bottom: -100px;
    position: fixed;
    opacity: 0;
  }
}

/* Standard syntax */

@keyframes alertdown {
  from {
    bottom: 15px;
    position: fixed;
    opacity: 1;
  }
  to {
    bottom: -100px;
    position: fixed;
    opacity: 0;
  }
}