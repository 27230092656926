@media screen and (max-width: 959px) {
    .MuiPaper-root.MuiCard-root.Mission-card  {
        width: 80% !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .MuiPaper-root.MuiCard-root.Mission-card > .MuiCardMedia-root {
        background-size: cover !important;
    }

}
@media screen and (max-width: 559px) {
    .MuiPaper-root.MuiCard-root.Mission-card  {
        width: 100% !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .MuiPaper-root.MuiCard-root.Mission-card > .MuiCardMedia-root {
        background-size: cover !important;
    }

}
/*MuiPaper-root MuiCard-root sc-fzomuh kyAKRl MuiPaper-elevation4 MuiPaper-rounded*/