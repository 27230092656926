html {
  margin: 0;
  padding: 0;
  width: 100vw;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;  
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(./themes/font/matfont.woff2) format('woff2');
}

.material-icons {  
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}


.Toastify__toast--error {
  background: var(--instagram-hover) !important;
  color: #fff !important;
  font-size: large;
  font-weight: 200;
  border-radius: 5px !important;
  padding: 5px;
  text-align: center;
  text-decoration: none;
}
 .Toastify__toast--warning {
  background: var(--instagram-hover) !important;
  font-size: large;
  font-weight: 200;
  border-radius: 5px !important;
  padding: 5px;
  text-align: center;
  text-decoration: none;
}
