.MuiDrawer-paper {
    background-color: var(--primary) !important;
}

a {
    text-decoration: none;
}

@media (max-width: 280px) {
    .MuiDrawer-paperAnchorRight {
        width: 80%;
        font-size: small;
    }
}